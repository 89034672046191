let localId = 0;

const exerciseBase = {
  exerciseSet: null,
  resource: null,
  description: null,
  isSuperset: false,
};

const nutritionSchemaVersion = '1.0';

const nutritionBase = {
  maintenance: {
    c: { value: 0, unit: 'grams' },
    p: { value: 0, unit: 'grams' },
    f: { value: 0, unit: 'grams' },
    total: { value: 0, unit: 'calories' },
  },
  surplus: {
    c: { value: 0, unit: 'grams' },
    p: { value: 0, unit: 'grams' },
    f: { value: 0, unit: 'grams' },
    total: { value: 0, unit: 'calories' },
  },
  deficit: {
    c: { value: 0, unit: 'grams' },
    p: { value: 0, unit: 'grams' },
    f: { value: 0, unit: 'grams' },
    total: { value: 0, unit: 'calories' },
  },
  dailyPlan: [0, 0, 0, 0, 0, 0, 0],
};

class Factory {
  static createExercise(sessionId, orderNo = 0, type = 'exercise') {
    return {
      sessionId,
      name: `New ${type}`,
      type,
      orderNo,
      ...exerciseBase,
    };
  }

  static createExerciseSet() {
    return {
      reps: '',
      load: '',
      unit: 'kg',
    };
  }

  static createExerciseResource(source = 'youtube') {
    return {
      source,
      url: '',
    };
  }

  static createId() {
    localId += 1;
    return String(localId);
  }

  static createNutrition() {
    return {
      version: nutritionSchemaVersion,
      ...nutritionBase,
    };
  }
}

export default Factory;
