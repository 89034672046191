<template>
  <div @mousedown="close" v-if="value" class="modal" tabindex="-1">
    <div @mousedown.stop class="modal-dialog modal-lg modal-dialog-scrollable modal-h-100">
      <div class="modal-content h-100">
        <div class="modal-header">
          <h5 class="modal-title">Session builder</h5>
          <button @click="close" type="button" class="btn-close"></button>
        </div>
        <div ref="content" class="modal-body p-0 d-flex">
          <SessionEditor
            @sessionEditorUpdate="updateSession"
            @sessionEditorAddItem="addSessionItem"
            @sessionEditorUpdateItem="updateSessionItem"
            @sessionEditorDeleteItem="deleteSessionItem"
            @sessionEditorSupersetItem="supersetSessionItem"
            @sessionEditorReorderItems="reorderSessionItems"
            @sessionEditorShowItemDetails="resetScroll"
            :session="session"
            :programId="programId"
            :athleteId="athleteId"
          ></SessionEditor>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { deepCopy } from '@/core/util';
import Factory from '@/core/factory';

export default {
  name: 'SessionEditorModal',
  components: {
    SessionEditor: () => import('@/elements/session/SessionEditor'),
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    programId: {
      type: String,
      default: null,
    },
    sessionId: {
      type: Number,
      required: true,
    },
    athleteId: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters('calendar', ['calendarGetSession']),
    ...mapGetters('program', ['programGetSession']),
    session() {
      const { programId, sessionId } = this;
      if (programId) {
        return deepCopy(this.programGetSession(this.sessionId));
      }
      return deepCopy(this.calendarGetSession(sessionId));
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    resetScroll() {
      this.$refs.content.scrollTop = 0;
    },
    async updateSession(payload) {
      const { programId } = this;
      const { sessionId } = this.session;
      try {
        const newPayload = { ...payload, sessionId };
        if (programId) {
          await this.$store.dispatch('program/updateSession', newPayload);
        } else {
          await this.$store.dispatch('calendar/updateSession', newPayload);
        }
      } catch (err) {
        if (programId) {
          this.$store.dispatch('program/resetSession', sessionId);
        } else {
          this.$store.dispatch('calendar/resetSession', sessionId);
        }
        this.$store.dispatch('addSystemError', err, { root: true });
      }
    },
    async addSessionItem(itemType) {
      const { programId } = this;
      const { sessionId, exercises } = this.session;
      try {
        const payload = Factory.createExercise(sessionId, exercises.length, itemType);
        if (programId) {
          await this.$store.dispatch('program/addSessionItem', payload);
        } else {
          await this.$store.dispatch('calendar/addSessionItem', payload);
        }
      } catch (err) {
        if (programId) {
          this.$store.dispatch('program/resetSession', sessionId);
        } else {
          this.$store.dispatch('calendar/resetSession', sessionId);
        }
        this.$store.dispatch('addSystemError', err, { root: true });
      }
    },
    async updateSessionItem(itemPayload) {
      const { programId } = this;
      const { sessionId } = this.session;
      try {
        if (programId) {
          await this.$store.dispatch('program/updateSessionItem', itemPayload);
        } else {
          await this.$store.dispatch('calendar/updateSessionItem', itemPayload);
        }
      } catch (err) {
        if (programId) {
          this.$store.dispatch('program/resetSession', sessionId);
        } else {
          this.$store.dispatch('calendar/resetSession', sessionId);
        }
        this.$store.dispatch('addSystemError', err, { root: true });
      }
    },
    async deleteSessionItem(itemId) {
      const { programId } = this;
      const { sessionId } = this.session;
      try {
        const payload = { sessionId, exerciseId: itemId };
        if (programId) {
          await this.$store.dispatch('program/deleteSessionItem', payload);
        } else {
          await this.$store.dispatch('calendar/deleteSessionItem', payload);
        }
      } catch (err) {
        if (programId) {
          this.$store.dispatch('program/resetSession', sessionId);
        } else {
          this.$store.dispatch('calendar/resetSession', sessionId);
        }
        this.$store.dispatch('addSystemError', err, { root: true });
      }
    },
    async supersetSessionItem(itemPayload) {
      const { programId } = this;
      const { sessionId } = this.session;
      const { exerciseId, isSuperset } = itemPayload;
      try {
        const payload = { sessionId, exerciseId, isSuperset };
        if (programId) {
          await this.$store.dispatch('program/supersetSessionItem', payload);
        } else {
          await this.$store.dispatch('calendar/supersetSessionItem', payload);
        }
      } catch (err) {
        if (programId) {
          this.$store.dispatch('program/resetSession', sessionId);
        } else {
          this.$store.dispatch('calendar/resetSession', sessionId);
        }
        this.$store.dispatch('addSystemError', err, { root: true });
      }
    },
    async reorderSessionItems(itemIds) {
      const { programId } = this;
      const { sessionId } = this.session;
      try {
        const payload = { sessionId, exerciseIds: itemIds };
        if (programId) {
          await this.$store.dispatch('program/reorderSessionItems', payload);
        } else {
          await this.$store.dispatch('calendar/reorderSessionItems', payload);
        }
      } catch (err) {
        if (programId) {
          this.$store.dispatch('program/resetSession', sessionId);
        } else {
          this.$store.dispatch('calendar/resetSession', sessionId);
        }
        this.$store.dispatch('addSystemError', err, { root: true });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-body {
  overflow-x: hidden;
}
</style>
